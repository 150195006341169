import React from "react";
import { Link } from "gatsby";
import {
  Header as OnboardingHeader,
  Next,
  Exit,
  GoBack
} from "components/Onboarding/OnboardingComponents";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  max-width: 50em;
  margin: 0 auto;
  padding: 24px;

  h2 {
    padding: 16px;
  }
`;

export default function projectStart() {
  // function exitClick() {
  //   if (process.env.NODE_ENV === "production") {
  //     typeof window !== "undefined" &&
  //       window.gtag("event", "click", {
  //         event_label: "before start",
  //         event_category: "Onboarding Exit"
  //       });
  //   }
  // }
  return (
    <>
      <OnboardingHeader />

      <Link style={{ color: "black" }} to="/home" >
        <Exit>&#10005;</Exit>
      </Link>

      <Container>
        <h2 className="text-2xl font-bold text-gray-900">
          Based on your interests, we've identified the perfect project for you
          to get started!
        </h2>

        <Next
          paintDrip
          to={
            typeof window != "undefined"
              ? localStorage.getItem("projectRecommendation")
              : null
          }
          hex="#438cee"
          duration={1}
        >
          Start coding &#8250;
        </Next>
        {typeof window != "undefined" &&
        localStorage.getItem("previousProjectId") != "undefined" ? (
          <GoBack
            paintDrip
            to={`/projects/${localStorage.getItem("previousProjectId")}`}
            hex="#438cee"
            duration={1}
          >
            No thanks, take me back to{" "}
            <span>{localStorage.getItem("previousProjectTitle")}</span> &#8250;
          </GoBack>
        ) : null}
      </Container>
    </>
  );
}
